@charset "UTF-8";
html {
  direction: rtl;
}
@media (min-width: 1700px) {
  .header__main {
    padding-left: 0;
    padding-right: 40px;
  }
}
.header__phone {
  padding-left: 17px;
  padding-right: 0;
}
@media (min-width: 576px) {
  .header__logo {
    margin-left: 13px;
    margin-right: auto;
    padding-left: 0;
    padding-right: 40px;
  }
}
@media (min-width: 992px) {
  .header__logo {
    padding-right: 0;
  }
}
@media (min-width: 992px) {
  .block-phone__icon {
    margin-left: 0;
    margin-right: 5px;
  }
}
@media (min-width: 992px) {
  .block-mail-us__icon {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 1500px) {
  .menu--parent:before {
    margin-left: 0;
    margin-right: 3px;
  }
}
@media (min-width: 1450px) {
  .menu__sub {
    left: auto;
    right: -23px;
  }
}
.block-banner__title img {
  right: 0;
}
.block-banner__title--authors img {
  margin-right: 0;
  left: 0;
  right: auto;
}
@media (min-width: 576px) {
  .block-banner__title--authors img {
    left: auto;
    margin-left: 0;
    margin-right: -20px;
    right: 100%;
  }
}
.block-banner__title--contacts img {
  margin-right: 0;
  left: 0;
  right: auto;
}
@media (min-width: 576px) {
  .block-banner__title--contacts img {
    left: auto;
    margin-left: 0;
    margin-right: 13px;
    right: 100%;
  }
}
.block-banner__title--team img,
.block-banner__title--special-services img {
  margin-right: auto;
  margin-left: 0;
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .block-banner__title--team img,
  .block-banner__title--special-services img {
    left: 100%;
    margin-left: -120px;
    margin-right: 0;
    right: auto;
  }
}
@media (min-width: 576px) {
  .block-banner__title--special-services img {
    margin-left: 7px;
    margin-right: 0;
  }
}
.block-banner__title--reviews img,
.block-banner__title--blog img,
.block-banner__title--testimonials img,
.block-banner__title--service img {
  left: auto;
  margin-right: 0;
  right: 0;
}
@media (min-width: 576px) {
  .block-banner__title--reviews img,
  .block-banner__title--blog img,
  .block-banner__title--testimonials img,
  .block-banner__title--service img {
    left: 100%;
    margin-left: 16px;
    margin-right: 0;
    right: auto;
  }
}
@media (min-width: 576px) {
  .block-banner__title--testimonials img {
    margin-left: -130px;
    margin-right: 0;
  }
}
@media (min-width: 576px) {
  .block-banner__title--service img {
    margin-left: -61px;
    margin-right: 0;
  }
}
@media (min-width: 576px) {
  .block-banner__title--service-2 img {
    margin-left: -81px;
    margin-right: 0;
  }
}
@media (min-width: 576px) {
  .block-banner__title--service-3 img {
    margin-left: 0;
    margin-right: -97px;
  }
}
@media (min-width: 576px) {
  .block-banner__title--service-4 img {
    margin-right: 0;
    margin-left: -50px;
  }
}
.block-banner__title--catalogue img {
  left: auto;
  margin-left: 0;
  margin-right: 0;
  right: 0;
}
@media (min-width: 576px) {
  .block-banner__title--catalogue img {
    left: 100%;
    margin-left: 10px;
    margin-right: 0;
    right: auto;
  }
}
@media (min-width: 576px) {
  .block-video__data {
    padding-right: 0;
    padding-left: 50px;
  }
}
.block-video__decor {
  left: 0;
  right: auto;
}
.block-video__decor img {
  transform: none;
}
@media (min-width: 1200px) {
  .block-video__decor {
    margin-left: -50px;
    margin-right: 0;
  }
}
.block-video__video {
  padding-left: 27px;
  padding-right: 14px;
}
@media (min-width: 768px) {
  .block-promote-offer__desc p strong {
    margin-left: 0;
    margin-right: 8px;
  }
}
.block-services {
  padding-top: 49px;
}
@media (min-width: 1200px) {
  .block-services__item--1 {
    padding-left: 0;
    padding-right: 84px;
  }
  .block-services__item--2 {
    padding-left: 15px;
    padding-right: 0;
  }
  .block-services__item--3 {
    padding-left: 147px;
    padding-right: 0;
  }
  .block-services__item--4 {
    padding-left: 159px;
    padding-right: 0;
  }
  .block-services__item--5 {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1200px) and (min-width: 1700px) {
  .block-services__item--5 {
    margin-left: 0;
    margin-right: -40px;
  }
}
.service ul li:before {
  margin-left: 6px;
  left: 100%;
  right: auto;
}
@media (min-width: 1200px) {
  .service--style-2 {
    flex-direction: row-reverse;
  }
  .service--style-3 {
    justify-content: flex-end;
  }
  .service--style-4 {
    flex-direction: row-reverse;
  }
  .service--style-5 {
    justify-content: flex-start;
  }
}
.service__side-off {
  margin-left: 36px;
  margin-right: 0;
}
@media (min-width: 1200px) {
  .service__side-off--style-2 {
    margin-left: 0;
    margin-right: 30px;
  }
  .service__side-off--style-3 {
    flex-direction: row-reverse;
  }
  .service__side-off--style-4 {
    margin-left: 0;
    margin-right: 22px;
  }
  .service__side-off--style-5 {
    flex-direction: row-reverse;
    margin-left: 16px;
  }
}
@media (min-width: 1200px) {
  .service__main--style-2 {
    text-align: left;
  }
  .service__main--style-4 {
    text-align: left;
  }
}
.service__header {
  flex-direction: row-reverse;
}
@media (min-width: 768px) {
  .service__header {
    flex-direction: row;
  }
}
@media (min-width: 1200px) {
  .service__header--style-2 {
    justify-content: flex-end;
    text-align: left;
  }
  .service__header--style-4 {
    justify-content: flex-end;
    text-align: left;
  }
}
.service__count {
  padding-left: 20px;
}
@media (min-width: 1200px) {
  .service__count {
    padding-left: 0;
  }
  .service__count--style-2 {
    padding-right: 36px;
  }
  .service__count--style-3 {
    margin-left: 22px;
  }
  .service__count--style-4 {
    margin-left: auto;
    margin-right: 58px;
  }
  .service__count--style-5 {
    margin-left: 0;
    margin-right: 22px;
  }
}
.service__title {
  margin-left: 4px;
  padding: 12px 15px 10px;
}
@media (min-width: 1200px) {
  .service__title--service-3 {
    padding-left: 58px;
  }
  .service__title--style-3 {
    padding-left: 67px;
  }
  .service__title--style-4 {
    padding-left: 30px;
  }
  .service__title--service-5 {
    padding-left: 58px;
  }
  .service__title--style-5 {
    padding-left: 67px;
  }
}
@media (min-width: 1200px) {
  .block-special-services__item--1 {
    padding-left: 75px;
    padding-right: 0;
  }
  .block-special-services__item--2 {
    padding-left: 15px;
    padding-right: 0;
  }
  .block-special-services__item--3 {
    padding-left: 82px;
    padding-right: 0;
  }
  .block-special-services__item--4 {
    padding-left: 159px;
    padding-right: 0;
  }
}
.special-service__side-off {
  margin-right: 10px;
}
.special-service__side-off--style-2,
.special-service__side-off--style-4 {
  margin-left: 10px;
  margin-right: 0;
}
@media (min-width: 768px) {
  .special-service__side-off--style-2,
  .special-service__side-off--style-4 {
    margin-left: 10px;
    margin-right: 0;
  }
}
@media (min-width: 1200px) {
  .special-service__side-off {
    margin-left: 36px;
    margin-right: 0;
  }
  .special-service__side-off--style-2 {
    margin-left: 17px;
    margin-right: 0;
  }
  .special-service__side-off--style-4 {
    margin-left: 29px;
    margin-right: 0;
  }
}
@media (min-width: 1200px) {
  .special-service__main {
    text-align: left;
  }
}
.special-service__count {
  padding-right: 20px;
}
.special-service__count span:before {
  right: auto;
  left: 100%;
  margin-left: 0px;
}
.special-service__count--style-2,
.special-service__count--style-4 {
  padding-right: 0;
  padding-left: 20px;
}
@media (min-width: 768px) {
  .special-service__count {
    padding-right: 65px;
    padding-left: 0;
  }
  .special-service__count--style-2,
  .special-service__count--style-4 {
    padding-left: 65px;
    padding-right: 0;
  }
  .special-service__count--style-2 span:before,
  .special-service__count--style-4 span:before {
    left: auto;
    right: 100%;
  }
  .special-service__count--singular {
    padding-right: 100px;
  }
}
@media (min-width: 1200px) {
  .special-service__count {
    padding-left: 0;
    padding-right: 75px;
  }
  .special-service__count span:before {
    margin-right: 0;
    margin-left: -24px;
  }
  .special-service__count--style-2 {
    padding-left: 69px;
    padding-right: 0;
  }
  .special-service__count--style-2 span:before {
    left: auto;
    margin-left: 0;
    margin-right: -7px;
    right: 100%;
  }
  .special-service__count--style-3 {
    padding-right: 60px;
  }
  .special-service__count--style-3 span:before {
    margin-left: -18px;
  }
  .special-service__count--style-4 {
    padding-left: 138px;
    padding-right: 0;
  }
  .special-service__count--style-4 span:before {
    left: auto;
    margin-left: 0;
    margin-right: 30px;
    right: 100%;
  }
}
.special-service__title-wrapper {
  padding-left: 0;
}
@media (min-width: 768px) {
  .special-service__title-wrapper--singular {
    padding-left: 20px;
  }
}
@media (min-width: 992px) {
  .special-service__title-wrapper:before {
    margin-left: 10px;
    margin-right: 10px;
  }
  .special-service__title-wrapper--singular {
    padding-left: 0;
  }
  .special-service__title-wrapper--style-2,
  .special-service__title-wrapper--style-4 {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1200px) {
  .special-service__title-wrapper {
    padding-left: 0;
  }
  .special-service__title-wrapper:before {
    margin-left: 0;
    margin-right: 7px;
  }
  .special-service__title-wrapper--style-2:before {
    margin-left: 7px;
    margin-right: 0;
  }
  .special-service__title-wrapper--style-3 {
    align-items: flex-end;
  }
  .special-service__title-wrapper--style-3:before {
    margin-right: 31px;
    margin-left: 0;
  }
  .special-service__title-wrapper--style-4:before {
    margin-right: 0;
    margin-left: 7px;
  }
}
.special-service__title {
  padding: 12px 15px 10px;
  text-align: left;
}
@media (min-width: 1200px) {
  .special-service__title--style-2 {
    margin-left: 0;
    text-align: right;
  }
  .special-service__title--style-4 {
    text-align: right;
  }
}
.special-service__excerpt {
  text-align: left;
}
.special-service__excerpt--style-2,
.special-service__excerpt--style-4 {
  text-align: right;
}
@media (min-width: 1200px) {
  .special-service__excerpt--style-3 {
    padding-left: 23px;
  }
}
.special-service__read-more {
  margin-right: 14px;
}
.page-block__sidebar {
  padding-left: 0;
}
@media (min-width: 1200px) {
  .page-block__main--catalogue {
    padding-left: 14px;
  }
}
@media (min-width: 1200px) {
  .page-block__title--public {
    padding-right: 41px;
    margin-left: -41px;
    padding-left: 0;
    margin-right: 0;
  }
}
.page-block__title--arrow-up img {
  right: 26px;
}
.columns__column--border-divide:before {
  left: auto;
  right: -1px;
}
.columns__column--main:after {
  left: auto;
  right: 3px;
}
@media (min-width: 992px) {
  .columns__column--main {
    padding-left: 47px;
    padding-right: 75px;
  }
}
.author {
  text-align: left;
}
.author__thumbnail {
  margin: 0 auto 0 0px;
}
@media (min-width: 576px) {
  .author__thumbnail {
    margin-right: 24px;
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .post__thumbnail {
    margin-left: 19px;
    margin-right: 0;
  }
}
.post__thumbnail--singular,
.post__thumbnail--recent {
  margin-left: 0;
}
.sidebar {
  padding-left: 11px;
  padding-right: 15px;
}
.form-widget__field,
.form-widget__select {
  padding: 12px 20px 13px;
}
.form-widget__field--search {
  padding-left: 70px;
}
.form-widget__label--select img {
  left: 24px;
  right: auto;
}
.form-widget__button--search {
  left: 0;
  right: auto;
}
.book {
  text-align: left;
}
.book__details {
  margin: 0 0 50px;
}
@media (min-width: 576px) {
  .book__details {
    margin: 0 24px 0 0;
  }
}
.testimonial {
  padding: 36px 30px 29px 30px;
}
@media (min-width: 768px) {
  .testimonial {
    padding: 36px 30px 29px 78px;
  }
}
@media (min-width: 768px) {
  .testimonial__info {
    border-left: 1px solid #fff;
    border-right: none;
    margin-left: 30px;
    margin-right: 0;
    padding-left: 34px;
    padding-right: 0;
  }
}
@media (min-width: 1200px) {
  .contact-form__wrapper {
    padding-right: 100px;
    padding-left: 0;
  }
}
