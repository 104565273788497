.header
	
	&__main
		@media (min-width: 1700px)
			padding-left: 0;
			padding-right: 40px;
			
	&__phone
		padding-left 17px
		padding-right 0
		
	&__logo
		
		@media (min-width screen-sm-min)
			margin-left 13px
			margin-right auto
			padding-left 0
			padding-right 40px

		@media (min-width screen-lg-min)
			padding-right 0