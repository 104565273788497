.block-video
	
	&__data
		
		@media (min-width screen-sm-min)
			padding-right 0
			padding-left 50px
		
	&__decor
		left 0
		right auto
		
		img
			transform none
		
		@media (min-width screen-xl-min)
			margin-left -50px
			margin-right 0
	
	&__video
		padding-left 27px
		padding-right 14px