.block-special-services
	
	&__item
		
		@media (min-width screen-xl-min)
			
			&--1
				padding-left 75px
				padding-right 0
			
			&--2
				padding-left 15px
				padding-right 0
			
			&--3
				padding-left 82px
				padding-right 0
			
			&--4
				padding-left 159px
				padding-right 0