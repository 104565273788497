.testimonial
	padding 36px 30px 29px 30px
	
	@media (min-width screen-md-min)
		padding 36px 30px 29px 78px
	
	&__info

		@media (min-width screen-md-min)
			border-left 1px solid color__light
			border-right none
			margin-left 30px
			margin-right 0
			padding-left 34px
			padding-right 0