.special-service

	&__side-off
		margin-right 10px
		
		&--style-2,
		&--style-4
			margin-left 10px
			margin-right 0
		
		@media (min-width screen-md-min)
			
			&--style-2,
			&--style-4
				margin-left 10px
				margin-right 0

		@media (min-width screen-xl-min)
			margin-left 36px
			margin-right 0
				
			&--style-2
				margin-left 17px
				margin-right 0

			&--style-4
				margin-left 29px
				margin-right 0
			
	&__main
		
		@media (min-width screen-xl-min)
			text-align left
		
	&__count
		padding-right 20px
		
		span
	
			&:before
				right auto
				left 100%
				margin-left 0px
				
		&--style-2,
		&--style-4
			padding-right 0
			padding-left 20px
		
		@media (min-width screen-md-min)
			padding-right 65px
			padding-left 0
		
			&--style-2,
			&--style-4
				padding-left 65px
				padding-right 0
				
				span
					
					&:before
						left auto
						right 100%
			
			&--singular
				padding-right 100px	
				
		
		@media (min-width screen-xl-min)
			padding-left 0
			padding-right 75px
				
			span

				&:before
					margin-right 0
					margin-left -24px

			&--style-2
				padding-left 69px
				padding-right 0
				
				span
					
					&:before
						left auto
						margin-left 0
						margin-right -7px
						right 100%
			
			&--style-3
				padding-right 60px
				
				span
					
					&:before
						margin-left -18px
			
			&--style-4
				padding-left 138px
				padding-right 0
				
				span
					
					&:before
						left auto
						margin-left 0
						margin-right 30px
						right 100%

	&__title-wrapper
		padding-left 0			
		
		@media (min-width screen-md-min)
			
			&--singular
				padding-left 20px
			
		@media (min-width screen-lg-min)
			
			&:before
				margin-left 10px
				margin-right 10px 

			&--singular
				padding-left 0
			
			&--style-2,
			&--style-4
				padding-left 0
				padding-right 0
				
		@media (min-width screen-xl-min)
			padding-left 0
			
			&:before
				margin-left 0
				margin-right 7px
			
			&--style-2
				
				&:before
					margin-left 7px
					margin-right 0
			
			&--style-3
				align-items flex-end
				
				&:before
					margin-right 31px
					margin-left 0
				
			&--style-4
				
				&:before
					margin-right 0
					margin-left 7px

	&__title
		padding 12px 15px 10px
		text-align left
		
		@media (min-width screen-xl-min)

			&--style-2
				margin-left 0
				text-align right
			
			&--style-4
				text-align right
		
	&__excerpt
		text-align left
		
		&--style-2,
		&--style-4
			text-align right
				
		@media (min-width screen-xl-min)
			
			&--style-3
				padding-left 23px
	
	&__read-more
		margin-right 14px