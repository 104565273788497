.post
	
	&__thumbnail
		
		@media (min-width screen-lg-min)
			margin-left 19px
			margin-right 0
			
		&--singular,
		&--recent
			margin-left 0