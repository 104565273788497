.service

	ul
		
		li

			&:before
				margin-left 6px
				left 100%
				right auto
			
		
	
	@media (min-width screen-xl-min)
	
		&--style-2
			flex-direction row-reverse
		
		&--style-3
			justify-content flex-end
			
		&--style-4
			flex-direction row-reverse
		
		&--style-5
			justify-content flex-start
	
	&__side-off
		margin-left 36px
		margin-right 0

		@media (min-width screen-xl-min)
				
			&--style-2
				margin-left 0
				margin-right 30px
			
			&--style-3
				flex-direction row-reverse

			&--style-4
				margin-left 0
				margin-right 22px
			
			&--style-5
				flex-direction row-reverse
				margin-left 16px
			
	&__main
		
		@media (min-width screen-xl-min)

			&--style-2
				text-align left
				
			&--style-4
				text-align left
	
	&__header
		flex-direction row-reverse
		
		@media (min-width screen-md-min)
			flex-direction row
			
		@media (min-width screen-xl-min)

			&--style-2
				justify-content flex-end
				text-align left
				
			&--style-4
				justify-content flex-end
				text-align left
		
	&__count
		padding-left 20px
		
		@media (min-width screen-xl-min)
			padding-left 0

			&--style-2
				padding-right 36px
			
			&--style-3
				margin-left 22px
			
			&--style-4
				margin-left auto
				margin-right 58px
			
			&--style-5
				margin-left 0
				margin-right 22px
		
	&__title
		margin-left 4px
		padding 12px 15px 10px
		
		@media (min-width screen-xl-min)
		
			&--service-3
				padding-left 58px

			&--style-3
				padding-left 67px
			
			&--style-4
				padding-left 30px
		
			&--service-5
				padding-left 58px

			&--style-5
				padding-left 67px