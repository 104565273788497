.block-banner

	&__title
		
		img
			right 0	
			
		&--authors
			
			img
				margin-right 0
				left 0
				right auto
	
				@media (min-width screen-sm-min)
					left auto
					margin-left 0
					margin-right -20px
					right 100%
					
		&--contacts
			
			img
				margin-right 0
				left 0
				right auto
	
				@media (min-width screen-sm-min)
					left auto
					margin-left 0
					margin-right 13px
					right 100%
			
		&--team,
		&--special-services
			
			img
				margin-right auto
				margin-left 0
				right 0
				left auto
	
				@media (min-width screen-sm-min)
					left 100%
					margin-left -120px
					margin-right 0
					right auto
		
		&--special-services
			
			@media (min-width screen-sm-min)
			
				img
					margin-left 7px
					margin-right 0
		
		&--reviews,
		&--blog,
		&--testimonials,
		&--service
			
			img
				left auto
				margin-right 0
				right 0
	
				@media (min-width screen-sm-min)
					left 100%
					margin-left 16px
					margin-right 0
					right auto
		
		&--testimonials
			
			img
				
				@media (min-width screen-sm-min)
					margin-left -130px
					margin-right 0
		
		&--service
			
			img
				
				@media (min-width screen-sm-min)
					margin-left -61px
					margin-right 0
		
		&--service-2
			
			img
			
				@media (min-width screen-sm-min)
					margin-left -81px
					margin-right 0
					
		&--service-3		
			
			img
		
				@media (min-width screen-sm-min)
					margin-left 0
					margin-right -97px
		
		&--service-4
			
			img
			
				@media (min-width screen-sm-min)
					margin-right 0
					margin-left -50px

		&--catalogue
			
			img
				left auto
				margin-left 0
				margin-right 0
				right 0
					
				@media (min-width screen-sm-min)
					left 100%
					margin-left 10px
					margin-right 0
					right auto