.columns
	
	&__column
		
		&--border-divide
			
			&:before
				left auto
				right -1px

		&--main
			
			&:after
				left auto
				right 3px
				
			@media (min-width: screen-lg-min)
				padding-left 47px
				padding-right 75px