.block-promote-offer
	
	&__desc
		
		p
			
			strong	
		
				@media (min-width screen-md-min)
					margin-left 0
					margin-right 8px