.form-widget

	&__field,
	&__select
		padding 12px 20px 13px
		
	&__field
		
		&--search
			padding-left 70px
		
	&__label
		
		&--select
			
			img
				left 24px
				right auto	

	&__button
		
		&--search
			left 0
			right auto