.block-services
	padding-top 49px
	
	&__item
		
		@media (min-width screen-xl-min)
			
			&--1
				padding-left 0
				padding-right 84px
			
			&--2
				padding-left 15px
				padding-right 0
			
			&--3
				padding-left 147px
				padding-right 0
			
			&--4
				padding-left 159px
				padding-right 0
				
			&--5
				padding-left 0
				padding-right 0
				
				@media (min-width: 1700px)
					margin-left 0
					margin-right -40px