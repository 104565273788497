.page-block
	
	&__sidebar
		padding-left 0
		
	&__main
		
		&--catalogue
			
			@media (min-width screen-xl-min)
				padding-left 14px
	
	&__title
		
		&--public
			
			@media (min-width screen-xl-min)
				padding-right 41px
				margin-left -41px
				padding-left 0
				margin-right 0
		
		&--arrow-up

			img
				right 26px